import listing from './listing'
import sshKeys from './ssh_keys'
import stop from './stop'
import start from './start'
import hardReboot from './hard_reboot'
import softReboot from './soft_reboot'
import detail from './detail'
import vncConsole from './vnc_console'
import resetPassword from './reset_password'
import unpause from './unpause'
import paused from './paused'

export default {
  listing,
  sshKeys,
  stop,
  start,
  hardReboot,
  softReboot,
  detail,
  vncConsole,
  resetPassword,
  paused,
  unpause
}
