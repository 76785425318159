<template>
  <div class="server-graph">
    <span class="mr-10 font-semibold">Thời gian: </span>
    <a-select class="mb-15" v-model="dateTimeSelected" :placeholder="$t('selectTime')" style="width: 200px;" :options="dateTime" />
    <a-card class="mb-15" >
      <iframe :src="`${grafana_url}&var-domain=${server_id}&theme=light&panelId=1&from=now-${dateTimeSelected}&to=now`" width="100%" height="350px" frameborder="0"></iframe>
    </a-card>
    <a-card class="mb-15" >
      <iframe :src="`${grafana_url}&var-domain=${server_id}&theme=light&panelId=2&from=now-${dateTimeSelected}&to=now`" width="100%" height="350px" frameborder="0"></iframe>
    </a-card>
    <a-card class="mb-15" >
      <iframe :src="`${grafana_url}&var-domain=${server_id}&theme=light&panelId=3&from=now-${dateTimeSelected}&to=now`" width="100%" height="350px" frameborder="0"></iframe>
      </a-card>
    <a-card class="mb-15" >
      <iframe :src="`${grafana_url}&var-domain=${server_id}&theme=light&panelId=6&from=now-${dateTimeSelected}&to=now`" width="100%" height="350px" frameborder="0"></iframe>
    </a-card>
  </div>
</template>
<script>
  export default {
    name: 'graph',
    props: {
      server_id: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        dateTimeSelected: '1h',
        dateTime: [
          {value: '1h', label: '1 ' + this.$t('server.graphsDateTime.hours')},
          {value: '6h', label: '6 ' + this.$t('server.graphsDateTime.hours')},
          {value: '12h', label: '12 ' + this.$t('server.graphsDateTime.hours')},
          {value: '1d', label: '1 ' + this.$t('server.graphsDateTime.dayAgo')},
          {value: '1w', label: '7 ' + this.$t('server.graphsDateTime.dayAgo')}
        ],
        grafana_url: process.env.VUE_APP_GRAFANA_URL
      }
    },
    methods: {
    }
  }
</script>
<style>
  .selected-time {
    width: fit-content;
    margin-left: auto;
  }
</style>
