<template>
  <div class="server-rebuild">
    <a-row type="flex" :gutter="24" justify="center">
        <a-col :span="24" :md="17" class="mb-20">
          <a-card :bordered="false" class="mb-15">
              <a-alert class="mb-10" show-icon type="warning" >
                <template #message>
                <p v-html="$t('server.rebuild.desc')"></p>
                </template>
              </a-alert>
              <h6 class="font-semibold mb-15 mt-20">{{$t('server.rebuild.selectOsImage')}}</h6>
              <WidgetSelectOs v-model="image_id" />
              <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="handleSubmit">
                    <a-alert class="mb-10" show-icon :message="$t('server.actionCreate.serverInfo.desc')" type="info" />
                    <a-alert class="mb-10" show-icon >
                      <template #message>
                        <p class="my-0">{{$t('server.rebuild.rules.title')}}</p>
                        <ul class="my-0">
                          <li>{{$t('server.rebuild.rules.capital')}}</li>
                          <li>{{$t('server.rebuild.rules.lowercase')}}</li>
                          <li>{{$t('server.rebuild.rules.special')}}</li>
                          <li>{{$t('server.rebuild.rules.number')}}</li>
                        </ul>
                        <p class="my-0">{{$t('server.rebuild.rules.hint')}}</p>
                        </template>
                    </a-alert>
                    <a-form-item :label="$t('server.rebuild.serverName')">
                      <a-input
                          v-decorator="['name', { rules: rules.name }]"
                      />
                    </a-form-item>
                    
                    <a-form-item :label="$t('server.rebuild.password')">
                      <a-input 
                          v-decorator="['password', { rules: rules.password }]"
                      />
                    </a-form-item>
                    <a-form-item :wrapper-col="{ span: 12, offset: 5 }" class="text-center">
                        <a-button 
                            type="primary" 
                            html-type="submit"
                            :loading="loading"
                            >{{$t('server.rebuild.btn')}}
                        </a-button>
                    </a-form-item> 
                </a-form>
          </a-card>
        </a-col>
      </a-row>
  </div>
</template>
<script>
  import rebuild from "@/services/server/rebuild"
  import WidgetSelectOs from "@/components/Widgets/WidgetSelectOs"
  import { notification } from 'ant-design-vue'
  import {PATTERN_NAME} from "@/constant"
  
  export default {
    components: {
      WidgetSelectOs,
		},
    props: {
      server_id: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        loading: false,
        image_id: '',
        form: this.$form.createForm(this, { name: 'server_info' }),
        rules: {
          name: [
            {required: true, message: this.$t('rulesForm.requiredServerName'), trigger: ['blur', 'change']},
            {min: 3, max: 32, message: this.$t('rulesForm.invalidServerCharactersLimited'), trigger: ['blur', 'change']},
            { pattern: PATTERN_NAME,
              message: this.$t('rulesForm.invalidServerName'),
              trigger: ['blur', 'change']
            }
          ],
          password: [
            { required: true, message: this.$t('server.rebuild.rules.message.required'), trigger: ['blur', 'change'] },
            { min: 10, message: this.$t('server.rebuild.rules.message.minLength'), trigger: ['blur', 'change'] },
            {
              // eslint-disable-next-line no-useless-escape
              pattern: '(?=^.{10,255}$)((?=.*\d)(?=.*[A-Z])(?=.*[a-z])|(?=.*\d)(?=.*[^A-Za-z0-9])(?=.*[a-z])|(?=.*[^A-Za-z0-9])(?=.*[A-Z])(?=.*[a-z])|(?=.*\d)(?=.*[A-Z])(?=.*[^A-Za-z0-9]))^.*',
              message: this.$t('server.rebuild.rules.message.invalid'),
            }

          ]
        },
      }
    },
    created() {
      // this.getData()
      
    },
    methods: {
      handleSubmit(e) {
        e.preventDefault();
        this.form.validateFields((err, values) => {
            if (err) {
                return;
            }
            this.loading = true

            let messages = []
            if (this._.isEmpty(this.image_id))
                messages.push('Bạn chưa chọn bộ cài')
            
            if (!this._.isEmpty(messages)) {
                this._.forEach(messages, val => {
                    notification.error({
                        message: val
                    })
                })
                return
            }
            values['image_id'] = this.image_id
            console.log(values)
            rebuild(this.server_id, values)
            .then(res=> {
              this.loading = false
              this.$router.push({name: 'servers'})
            })
        });
      },
      
    },
    mounted: function() {
      this.form.setFieldsValue({
        password: this._.sampleSize('abcdTUXZ234@$*@$*', 15).join('')
      });
    }
  }
</script>
<style>
  .server-rebuild{
    .ant-card-body{
      padding-top: 0;
    }
  }
</style>
