<template>
  <div class="server-ips">
    <a-card :bordered="false">
      <template #title>
        <a-row type="flex" align="middle">
          <a-col :span="24" :md="12">
            <h6>{{$t('server.ip')}}</h6>			
          </a-col>
        </a-row>
      </template>
      <a-button type="primary" slot="extra" size="small" @click="showModal">{{$t('server.manageIp.buttonBuyIp')}}</a-button>
      <CardProcess @ipReload="getData" />
      <a-table :columns="columns" :data-source="rows" :pagination="false" :loading="loading">
        <template slot="version" slot-scope="version">
          IPv{{version}}
        </template>
        <template slot="action" slot-scope="row">
          <a-button v-if="row.addr !== _.get(rows, '[0].addr')" type="danger" icon="delete" size="small" @click="deleteIP(row)">
            {{$t('server.manageIp.delete')}}
          </a-button>
          <a-button v-else type="danger" icon="delete" size="small" @click="showInfoIPDefault(row)">
            {{$t('server.manageIp.delete')}}
          </a-button>
        </template>
      </a-table>
    </a-card>
    <a-modal 
        :title="$t('server.manageIp.dialog.title')"
        v-model="visible"
        :okText="$t('popup.confirm')"
        :cancelText="$t('popup.cancel')"
        :confirmLoading="confirmLoading"
        @ok="handleSubmit"
        @cancel="handleCancel"
        >
        <a-form :form="portIP" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="handleSubmit">
          <a-form-item :label="$t('server.manageIp.dialog.ipV4')">
              <a-select
                v-decorator="['qty', { rules: [{ required: true, message: 'Bạn chưa chọn số lượng!' }] }]"
                placeholder="Số lượng"
                @change="selectQty"
              >
                <a-select-option v-for="i in 5" :value="i">{{i}}</a-select-option>
              </a-select>
          </a-form-item>
          <WidgetSelectTerm
            :params="form.config"
            :type="form.type"
            v-model="WidgetSelectTermData"
          />
      </a-form>
    </a-modal>
  </div>
</template>
<script>
  import OrderService from '@/services/order'
  import deletePortIP from '@/services/network/deletePortIP'
  import WidgetSelectTerm from "@/components/Widgets/WidgetSelectTerm"
  import { notification } from 'ant-design-vue'
  import {ORDER_TYPE_IP} from "@/constant"
  import CardProcess from "@/components/Cards/CardProcess"
  import serverDetail from "@/services/server/detail"

  export default {
    components: {
      WidgetSelectTerm,
      CardProcess
		},
    props: {
      server: {
        type: Object,
        default: ()=> ({})
      }
    },
    watch: {
      WidgetSelectTermData: function (val) {
        this.form.interval = val.interval
      }
    },
    data () {
      return {
        visible: false,
        confirmLoading: false,
        loading: false,
        rows: this._.get(this.server, 'network.public_ips', []),
				columns: [
					{
						title: 'IP',
						dataIndex: 'addr'
					},
					{
            title: this.$t('server.manageIp.type'),
            scopedSlots: { customRender: 'version' },
            dataIndex: 'version'
					},
					{
						title: this.$t('action'),
						scopedSlots: { customRender: 'action' },
					}
				],
        form: {
          type: ORDER_TYPE_IP,
          config: {
            qty: 0
          },
          info: {
            server_id: this.server.id
          },
          interval: ''
        },
        portIP: this.$form.createForm(this, { name: 'portIP' }),
        WidgetSelectTermData: {},
      }
    },
    methods: {
      async getData() {
        this.loading= true
        let res     = await serverDetail(this.server.id)
        this.rows   = this._.get(res, 'network.public_ips', [])
        console.log(this.rows)
        this.loading= false
      },
      showModal() {
        this.visible = true;
      },
      handleCancel(e) {
        this.visible = false;
      },
      selectQty(v) {
        this.form.config = {
          qty: v
        }
      },
      handleSubmit(e) {
          e.preventDefault();
          this.portIP.validateFields((err) => {
              if (err) {
                return;
              }
              let messages = []
              
              if (this.form.interval === '')
                messages.push('Bạn chưa chọn kỳ hạn thanh toán')
              
              if (!this._.isEmpty(messages)) {
                this._.forEach(messages, val => {
                  notification.error({
                    message: val
                  })
                })
                return
              }

              this.createOrder()
          });
      },
      createOrder() {
          this.confirmLoading = true
          OrderService.create(this.form)
          .then(res => {
              this.confirmLoading = false
              if (this._.isObject(res) && res.status == 1) {
                  notification.success({
                      message: 'Tạo đơn hàng thành công'
                  })
                  this.$router.push({name: 'billing.payment', params: {id: res.data.invoice_id}})
              } else {
                  notification.error({
                      message: res.message
                  })
              }
          })
      },
      showInfoIPDefault(row) {
				this.$info({
					title: `${this.$t('server.manageIp.titleDelete')} [${row.addr}]`,
					okText: this.$t('popup.confirm'),
					content: this.$t('server.manageIp.descDefault'),
					onOk() {},
				});
			},
      deleteIP(row) {
        this.$confirm({
          title: `${this.$t('server.manageIp.titleDelete')} [${row.addr}]`,
          content: this.$t('server.manageIp.confirmText'),
          okText: this.$t('server.manageIp.delete'),
          okType: 'danger',
          cancelText: this.$t('server.manageIp.dialog.close'),
          onOk: async ()=> {
            const res = await deletePortIP(row.addr, this.server.id)
            if (this._.isObject(res)) {
              notification.success({
                message: this.$t('server.manageIp.notify.deletedIp')
              })
              for(let i in this.rows) {
                if (this.rows[i].addr == row.addr) {
                  this.rows.splice(i, 1)
                }
              }
            } else {
              notification.error({
                message: this.$t('server.manageIp.notify.deleteIpError')
              })
            }
          }
        });
      }
    }
  }
</script>
