<template>
  <div class="server-resize">
    <a-row type="flex" :gutter="24">
        <a-col :span="24" :md="16" class="mb-20">
          <a-card :bordered="false" class="mb-15">
              <h6 class="font-semibold mb-15">{{$t('server.resizeAction.title')}}</h6>
              <WidgetSliderConfig 
                v-model="form.config"
              />
              <a-row v-if="!_.isEmpty(money_info)" type="flex" justify="center" :gutter="24" class="mt-10 mb-30">
                <a-col :span="18" class="text-center mt-20">
                  <a-descriptions :column="1">
                    <a-descriptions-item label="Tổng tiền">
                    {{currency(money_info.total_money)}} VNĐ
                    </a-descriptions-item>
                    <a-descriptions-item label="Chiết khấu">
                    {{currency(order_current.refund_money)}} VNĐ
                    </a-descriptions-item>
                    <a-descriptions-item label="Cần thanh toán">
                      <h5 class="font-semibold text-primary">{{currency(money_info.total_money-order_current.refund_money)}} VNĐ</h5>
                    </a-descriptions-item>
                  </a-descriptions>
                  <a-button @click="createOrder" :disabled="disableCreateOrder" :loading="fetchCreateOrder" type="primary" >{{$t('server.resizeAction.btnConfirm')}}</a-button>
                </a-col>
              </a-row>
            </a-card>
        </a-col>
        <a-col :span="24" :md="8" class="mb-20">
          <a-card :bordered="false" class="mb-15">
              <div class="content">
              <div class="mb-1">
                <h6 class="font-semibold mb-15">{{$t('server.resizeAction.invoiceSidebar.currentPack')}}</h6>
                <table v-if="!_.isEmpty(order_current)">
                  <tr v-for="product in order_current.products">
                    <td>{{product.name}}:</td>
                    <td class="price">{{product.value}}</td>
                  </tr>
                  <tr>
                    <td><b>{{order_current.interval_text}}:</b></td>
                    <td class="price"><b>{{currency(order_current.total_money)}} VNĐ</b></td>
                  </tr>
                </table>
              </div>
              <hr>
              <div class="mb-1" v-if="!_.isEmpty(money_info)">
                <h6 class="font-semibold mb-15 mt-15">{{$t('server.resizeAction.invoiceSidebar.newPack')}}</h6>
                <table>
                  <tr>
                    <td>Core CPU:</td>
                    <td class="price">{{form.config.cpu}}</td>
                  </tr>
                  <tr>
                    <td>RAM:</td>
                    <td class="price">{{form.config.ram}}</td>
                  </tr>
                  <tr>
                    <td>ROOT Disk:</td>
                    <td class="price">{{form.config.disk}} {{form.config.disk_type.toUpperCase()}}</td>
                  </tr>
                  <tr>
                    <td><b>{{money_info.label}}:</b></td>
                    <td class="price"><b>{{currency(money_info.total_money)}} VNĐ</b></td>
                  </tr>
                  
                </table>
              </div>
            </div>
          </a-card>
        </a-col>
      </a-row>
  </div>
</template>
<script>
  import OrderService from '@/services/order'
  import orderCurrent from "@/services/order/resources"
  import WidgetSliderConfig from "@/components/Widgets/WidgetSliderConfig"
  import { notification } from 'ant-design-vue'
  import {ORDER_TYPE_INSTANCE, ORDER_RESIZE_SERVER} from "@/constant"
  import {currency} from "@/helper"
  
  export default {
    components: {
      WidgetSliderConfig,
		},
    props: {
      server: {
        type: Object,
        default: ()=> ({})
      }
    },
    data () {
      return {
        form: {
          type: ORDER_RESIZE_SERVER,
          config: {},
          interval: '',
          info: {
            server_id: ''
          }
        },
        fetchCreateOrder: false,
        disableCreateOrder: true,
        currency,
        order_current: {},
        func_delay: null,
        money_info: {}
      }
    },
    watch: {
      'form.config': function (val) {
         if (val.cpu == this.server.config.vcpus && val.ram == this.server.config.ram && val.disk <= this.server.config.rootdisk) {
          this.disableCreateOrder = true
        } else {
          this.disableCreateOrder = false
        }
        if (_.isEmpty(this.order_current)) return
        if (this.func_delay !== null)
					clearTimeout(this.func_delay)
				this.func_delay = this.getMoney()
      }
    },
    async created() {
      this.loading = true
      this.order_current = (await orderCurrent(this.server.id)).data
      this.form.interval = this.order_current.interval
      this.form.info.server_id = this.server.id
      this.loading = false
    },
    methods: {
      getMoney() {
				return setTimeout(()=> {
					this.loading = true
					OrderService.calculate(this._.merge({'type': ORDER_TYPE_INSTANCE}, this.form.config))
					.then(res => {
						if (this._.isObject(res)) {
							this.loading = false
							this.money_info = this._.get(res, `data.${this.order_current.interval}`, {})
						}
					})
				}, 450)
			},
      createOrder() {
        this.fetchCreateOrder = true
        OrderService.create(this.form)
        .then(res => {
            this.fetchCreateOrder = false
            if (this._.isObject(res) && res.status == 1) {
                notification.success({
                    message: 'Tạo đơn hàng thành công'
                })
                this.$router.push({name: 'billing.payment', params: {id: res.data.invoice_id}})
            } else {
                notification.error({
                    message: res.message
                })
            }
        })
      }
    },
    mounted() {
      this.form.config = {
        ram: this.server.config.ram,
        cpu: this.server.config.vcpus,
        disk: this.server.config.rootdisk,
        disk_type: this.server.config.disk_type,
      }
    }
  }
</script>
<style>
  .server-resize{
    .ant-card-body{
      padding-top: 0;
    }
  }
</style>
