<template>
	<a-row type="flex" :gutter="24" class="server-detail">
        <a-col :span="24" class="mb-20">
            <a-card :bordered="false" class="mb-15" :loading="loading">
                <template #title v-if="!_.isEmpty(row)">
                    <span v-if="row.status === SERVER_ACTIVE" class="icon-server-lg-active"></span>
                    <span v-else class="icon-server-lg-shutoff"></span>
                    <div class="server-detail-title">
                        <h6 class="font-semibold m-0">{{row.name}}</h6>
                        <span class="sub-title">{{filtersServerStatus(row.status)}} / {{row.config.vcpus}} CPU / RAM {{row.config.ram}} {{STORAGE_UNIT}} / {{row.config.disk}} {{STORAGE_UNIT}} {{row.config.disk_type.toUpperCase()}} / {{row.os}}</span>
                    </div>
                </template>
                <a-tabs v-model:activeKey="activeKey" @change="tabChange">
                    <a-tab-pane key="actions">
                        <template #tab>
                            <span class="server-detail-icon icon-logs-primary"></span>
                            {{$t('action')}}
                        </template>
                        <DetailAction :server="row"/>
                    </a-tab-pane>
                    <!-- <a-tab-pane key="graphs">
                        <template #tab>
                            <span class="server-detail-icon icon-graphs-primary"></span>
                            {{$t('server.graphs')}}
                        </template>
                        <DetailGraph v-if="activeKey === 'graphs'" :server_id="row.id" />
                    </a-tab-pane> -->
                    <!-- <a-tab-pane key="logs">
                        <template #tab>
                            <span class="server-detail-icon icon-logs-primary"></span>
                            {{$t('server.logs')}}
                        </template>
                        <DetailLogConsole v-if="activeKey === 'logs'"  :server_id="row.id"/>
                    </a-tab-pane> -->
                    <!-- <a-tab-pane key="ips">
                        <template #tab>
                            <span class="server-detail-icon icon-ip-white"></span>
                            {{$t('server.ip')}}
                        </template>
                        <DetailIP v-if="activeKey === 'ips'"  :server="row"/>
                    </a-tab-pane> -->
                    <!-- <a-tab-pane key="rebuild">
                        <template #tab>
                            <span class="server-detail-icon icon-rebuild-primary"></span>
                            Cài lại OS
                        </template>
                        <DetailRebuild :server_id="row.id" />
                    </a-tab-pane> -->
                    <a-tab-pane key="resize">
                        <template #tab>
                            <span class="server-detail-icon icon-resize-primary"></span>
                            Đổi cấu hình
                        </template>
                        <DetailResize :server="row" />
                    </a-tab-pane>
                    <!-- <a-tab-pane key="delete">
                        <template #tab>
                            <span class="server-detail-icon icon-delete-primary"></span>
                            Xóa Server
                        </template>
                        
                    </a-tab-pane> -->
                </a-tabs>
            </a-card>
        </a-col>
	</a-row>
</template>

<script>

	import DetailGraph from "@/components/Server/DetailGraph"
	import DetailLogConsole from "@/components/Server/DetailLogConsole"
	import DetailRebuild from "@/components/Server/DetailRebuild"
	import DetailResize from "@/components/Server/DetailResize"
	import DetailAction from "@/components/Server/DetailAction"
	import DetailIP from "@/components/Server/DetailIP"
    import server from "@/services/server"
    import {SERVER_ACTIVE,SERVER_SHUTOFF, STORAGE_UNIT} from "@/constant"

	export default ({
		components: {
            DetailGraph,
            DetailLogConsole,
            DetailRebuild,
            DetailResize,
            DetailAction,
            DetailIP
		},
		data() {
			return {
                SERVER_ACTIVE,
                SERVER_SHUTOFF,
                STORAGE_UNIT,
                activeKey: '',
                row: {},
                loading: true,
			}
		},
        created() {
			this.activeKey = this._.get(this.$route, 'query.action', 'actions')
            this.getData()
		},
        methods : {
            tabChange(v) {
                window.history.pushState({},'', `?action=${v}`)
            },
            async getData() {
                this.loading= true
                this.row    = await server.detail(this._.get(this.$route, 'params.id'))
                this.loading= false
            },
            filtersServerStatus (value) {
                switch (value) {
                    case SERVER_ACTIVE:
                    value = this.$t('server.activeStatus')
                    break
                    case SERVER_SHUTOFF:
                    value = this.$t('server.shutOffStatus')
                    break
                }
                return value
            }
        }
	})

</script>

<style lang="scss" scoped>
p{
    color: #303133;
}
span.sub-title{
    font-size: 14px;
    font-weight: 300;
    margin: 0;
}
.icon-server-lg-active,
.icon-server-lg-shutoff{
    position: absolute;
    left: 7px;
}
.server-detail-title{
    padding-left: 48px;
}
.server-detail-icon{
    position: absolute;
    left: 7px;
    top: 9px;
}
</style>