<template>
  <a-tabs v-model:activeKey="activeKey" type="card" @change="tabChange" tabPosition="top" size="small">
    <a-tab-pane key="info" :tab="$t('server.info')">
        <DetailInfo :info="server" />
    </a-tab-pane>
    <a-tab-pane key="console" tab="Console" class="console" :class="{'full-size': console.fullSize}">
      <a-button v-if="console.fullSize" type="primary" size="small" class="btn-full-size" icon="shrink" @click="console.fullSize = false">Thu nhỏ</a-button>
      <a-button v-else type="primary" size="small" class="btn-full-size" icon="arrows-alt" @click="console.fullSize = true">Phóng to</a-button>
      <iframe width="100%" height="100%" :src="console.src"></iframe>
    </a-tab-pane>
    <a-tab-pane key="power" tab="Power">
      <a-alert :message="$t('server.power.desc')" show-icon type="warning" />
      <div class="text-center mt-15">
        <!-- <a-button class="mr-15" :loading="powerLoading" v-if="server.status === SERVER_ACTIVE" type="primary"  @click="power(SERVER_PAUSED)" size="small" icon="pause-circle">{{$t('confirm')}} Pause</a-button> -->
        <!-- <a-button class="mr-15" :loading="powerLoading" v-else-if="server.status === SERVER_PAUSED" type="primary" @click="power(SERVER_UNPAUSE)" size="small" icon="play-circle">{{$t('confirm')}} Unpause</a-button> -->
        <a-button class="mr-15" :loading="powerLoading" v-if="server.status === SERVER_SHUTOFF" type="primary"  @click="power(SERVER_ACTIVE)" size="small" icon="check-circle">{{$t('confirm')}} {{$t('server.start')}}</a-button>
        <a-button class="mr-15" :loading="powerLoading" v-if="server.status === SERVER_ACTIVE" type="danger" @click="power(SERVER_SHUTOFF)" size="small" icon="poweroff">{{$t('confirm')}} {{$t('server.stop')}}</a-button>
      </div>
    </a-tab-pane>
    <a-tab-pane key="reboot" class="reboot" tab="Restart">
      <a-alert :message="$t('server.reboot.desc')" show-icon type="info" />
      <div class="text-center mt-15">
          <a-radio-group v-model="mode_reboot">
              <a-radio :style="{display: 'block', marginBottom: '10px'}" :value="'soft_reboot'">
              {{$t('server.reboot.soft_reboot')}}
              </a-radio>
              <a-radio :style="{display: 'block', marginBottom: '10px'}" :value="'hard_reboot'">
              {{$t('server.reboot.hard_reboot')}}
              </a-radio>
          </a-radio-group>
          <a-button type="primary"  @click="reboot()" size="small" icon="reload">{{$t('server.reboot.btn')}}</a-button>
      </div>
    </a-tab-pane>
    <a-tab-pane key="reset-password" tab="Reset Password">
      <a-alert show-icon type="warning">
        <template #message><div v-html="$t('server.resetPassword.desc')"></div></template>
      </a-alert>
      <div class="text-center mt-15">
          <a-button type="primary"  @click="resetPassword()" size="small" icon="key">{{$t('server.resetPassword.btn')}}</a-button>
      </div>
    </a-tab-pane>
    <a-tab-pane key="delete" tab="Xóa Server">
      <a-alert show-icon type="warning">
        <template #message><div v-html="$t('server.destroy.descDontSaveDisk')"></div></template>
      </a-alert>
      <div class="text-center mt-15">
          <a-button type="danger"  @click="deleteServer()" size="small" icon="delete">{{$t('server.destroy.btn')}}</a-button>
      </div>
    </a-tab-pane>
  </a-tabs>
</template>
<script>
  import server from "@/services/server"
  import retrieve from "@/services/retrieve/create"
  import {SERVER_ACTIVE, SERVER_SHUTOFF, SERVER_UNPAUSE, SERVER_PAUSED, ORDER_TYPE_INSTANCE} from "@/constant"
  import { notification } from 'ant-design-vue'
  import DetailInfo from "@/components/Server/DetailInfo"

  export default {
    name: 'actions',
    components: {
      DetailInfo,
		},
    props: {
      server: {
        type: Object,
        default: ()=>{},

      }
    },
    data: ()=> ({
      SERVER_SHUTOFF,
      SERVER_ACTIVE,
      SERVER_UNPAUSE,
      SERVER_PAUSED,
      activeKey: '',
      powerLoading: false,
      loading: true,
      mode_reboot: 'soft_reboot',
      console: {
        src: '',
        fullSize: false
      }
    }),
    created() {
      this.activeKey = this._.get(this.$route, 'query.tab', 'info')
    },
    watch: {
      activeKey: async function(v) {
        if (v === 'console') {
          let res = await server.vncConsole(this.server.id)
          console.log(res)
          this.console.src = this._.get(res, 'console.url')
        }
      }
    },
    methods: {
      tabChange(v) {
        window.history.pushState({},'', `?action=actions&tab=${v}`)
      },
      async getData() {
          this.loading= true
          this.row    = await server.detail(this._.get(this.$route, 'params.id'))
          this.loading= false
      },
      async power(status) {
          this.powerLoading = true
          switch (status) {
            case SERVER_ACTIVE:
              await server.start(this.server.id)
            break;

            case SERVER_SHUTOFF:
              await server.stop(this.server.id)
            break;

            case SERVER_UNPAUSE:
              await server.unpause(this.server.id)
            break;

            case SERVER_PAUSED:
              await server.paused(this.server.id)
            break;
          }
         
          this.server.status = status == SERVER_UNPAUSE ? SERVER_ACTIVE : status
          this.powerLoading = false
      },
      reboot() {
          const mode_reboot   = this.mode_reboot
          const server_id     = this.server.id
          let message = this.$t('notify.success.reboot')
          this.$confirm({
              title: this.$t('server.reboot.dialogConfirm.title'),
              content: this.$t('server.reboot.dialogConfirm.sure'),
              cancelText: this.$t('popup.cancel'),
              okText: this.$t('popup.confirm'),
              onOk: async ()=> {
                  if (mode_reboot == 'soft_reboot') {
                      await server.softReboot(server_id)
                  } else {
                      await server.hardReboot(server_id)
                  }
                  notification.success({
                      message
                  })
              },
              onCancel() {}
          })
      },
      resetPassword() {
          const server_id    = this.server.id
          let message = this.$t('notify.success.resetPassword')
          this.$confirm({
              title: this.$t('server.resetPassword.dialogConfirm.title'),
              content: this.$t('server.resetPassword.dialogConfirm.sure'),
              cancelText: this.$t('popup.cancel'),
              okText: this.$t('server.resetPassword.dialogConfirm.btn'),
              async onOk() {
                  await server.resetPassword(server_id)
                  notification.success({
                      message
                  })
              },
              onCancel() {}
          })
      },
      deleteServer() {
        let reference_ids = this.server?.volumes_attached?.map(i => i.id) || []
        reference_ids.push(this.server.id)
        
        this.$confirm({
          title: `${this.$t('server.destroy.title')} [${this.server.name}]`,
          content: this.$t('server.destroy.dialogConfirm.sure'),
          okText: this.$t('server.destroy.btn'),
          okType: 'danger',
          cancelText: this.$t('popup.cancel'),
          onOk: async ()=> {
            const res = await retrieve({
              reference_ids,
              type : ORDER_TYPE_INSTANCE
            })
            if (this._.isObject(res)) {
              notification.success({
                message: this.$t('notify.success.retrieveServer')
              })
              this.$router.push({name: 'servers'})
            }
          }
        });
      }
    }
  }
</script>
<style lang="scss">
.reboot{
    .ant-radio-group{
        width: 300px;
        display: block;
        margin: auto;
        text-align: left;
        margin-bottom: 20px;
        lable {
            margin-bottom: 10px;
            display: block;
        }
    }
}
.console{
  iframe{
    height: 500px;
    width: 100%;
  }
  .btn-full-size{
    position: absolute;
  }
}
.console.full-size{
  .btn-full-size{
    z-index: 1001;
    position: fixed;
    left: 0;
    top: 0;
  }
  iframe{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    height: 100%;
  }
}
</style>