<template>
<a-row type="flex" :gutter="24" >
  <a-col :span="24" class="mb-15">
    <h6 class="font-semibold clearfix">
      {{$t('server.info')}}
      <a-button type="primary" class="update-info" slot="extra" size="small" @click="showModalEdit">{{$t('update')}}</a-button>
    </h6>
    <a-descriptions>
      <a-descriptions-item label="CPU">
        {{info.config.vcpus}} Core
      </a-descriptions-item>
      <a-descriptions-item label="Ram">
        {{info.config.ram}} {{STORAGE_UNIT}}
      </a-descriptions-item>
      <a-descriptions-item label="ROOT Disk">
        {{info.config.disk}} {{STORAGE_UNIT}} {{info.config.disk_type.toUpperCase()}}
      </a-descriptions-item>
      <a-descriptions-item label="OS">
        {{info.os}}
      </a-descriptions-item>
      <a-descriptions-item label="IP LAN">
       {{_.get(info.network, 'private_ips[0].addr')}}
      </a-descriptions-item>
      <a-descriptions-item label="IP WAN">
        {{_.get(info.network, 'public_ips[0].addr')}}
      </a-descriptions-item>
      <a-descriptions-item label="IPv6">
        
      </a-descriptions-item>
      <a-descriptions-item label="SSH Key">
        {{info.key_name ? info.key_name : $t('no')}}
      </a-descriptions-item>
      <a-descriptions-item :label="$t('created')">
        {{info.created | moment("HH:mm DD-MM-YYYY")}}
      </a-descriptions-item>
    </a-descriptions>
    <a-modal 
        :title="$t('update') +' server: '+ info.name"
        v-model="visible"
        :okText="$t('popup.confirm')"
        :cancelText="$t('popup.cancel')"
        :confirmLoading="confirmLoading"
        @ok="handleSubmit"
        @cancel="handleCancel"
        >
        <a-form :form="serverInfo" @submit="handleSubmit">
          <a-form-item :label="$t('server.actionCreate.serverInfo.name')">
            <a-input
              v-decorator="['name', { rules: rules.name }]"
            />
          </a-form-item>
      </a-form>
    </a-modal>
  </a-col>
  <a-col :span="24">
    <h6 class="font-semibold">{{$t('server.historiesTitle')}}</h6>
    <a-table v-if="!loading" :columns="columns" :data-source="histories" :pagination="false">
			<template slot="created" slot-scope="created">
				<span>{{ created | moment("HH:mm  DD-MM-YYYY") }}</span>
			</template>
		</a-table>
  </a-col>
</a-row>
</template>
<script>
  import histories from "@/services/server/histories"
  import updateInfo from "@/services/server/update"
  import {STORAGE_UNIT, PATTERN_NAME} from "@/constant"
  import { notification } from 'ant-design-vue'

  export default {
    name: 'info',
    props: {
      info: {
        type: Object,
        default: ()=>{}
      }
    },
    data () {
      return {
        histories: [],
        loading: true,
        STORAGE_UNIT,
        visible: false,
        columns: [
          {
            title: this.$t('action'),
            dataIndex: 'action'
          },
          {
            title: this.$t('created'),
            dataIndex: 'start_time',
            scopedSlots: { customRender: 'created' },
          }
        ],
        rules: {
          name: [
              {required: true, message: this.$t('rulesForm.requiredServerName'), trigger: ['blur', 'change']},
              {min: 3, max: 32, message: this.$t('rulesForm.invalidServerCharactersLimited'), trigger: ['blur', 'change']},
              { pattern: PATTERN_NAME,
              message: this.$t('rulesForm.invalidServerName'),
              trigger: ['blur', 'change']
              }
          ]
        },
        confirmLoading: false,
        serverInfo: this.$form.createForm(this, { name: 'serverInfo' }),
      }
    },
    created() {
      histories(this.info.id).then(res=> {
        this.histories = res
        this.loading = false
      })
    },
    methods: {
      showModalEdit() {
        this.visible = true;
        setTimeout(()=> {
          this.serverInfo.setFieldsValue({
            name: this.info.name
          })
        }, 1)
      },
      handleCancel() {
        this.visible = false;
      },
      handleSubmit(e) {
        this.confirmLoading = true
        e.preventDefault();
        this.serverInfo.validateFields((err, values) => {
            if (err) {
              return;
            }
            updateInfo(this.info.id, values)
            .then(res=> {
              this.info.name = values.name
              notification.success({
                message: this.$t('server.update.successNotify')
              })
              this.confirmLoading = false
              this.handleCancel()
            })
        });
      },
    },
    mounted() {
      
    }
  }
</script>