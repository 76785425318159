<template>
  <div class="server-log-console">
    <a-button type="primary" size="small" class="reload-logs" icon="reload" :loading="loading" @click="getData">{{$t('server.getLogs')}}</a-button>
    <a-card class="mb-15" :loading="loading">
      <pre>{{logs}}</pre>
    </a-card>
    
  </div>
</template>
<script>
  import logConsole from "@/services/server/log_console"

  export default {
    props: {
      server_id: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        logs: '',
        loading: true
      }
    },
    created() {
      this.getData()
    },
    methods: {
      getData() {
        this.loading = true
        logConsole(this.server_id).then(res=> {
          this.logs = res
          this.loading = false
        })
      }
    }
  }
</script>
<style>
  .reload-logs{
    z-index: 11;
    position: absolute !important;
    right: 0;
  }
</style>
