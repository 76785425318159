<template>
	<div>
		<a-list
			class="process-list"
			item-layout="horizontal"
			:split="false"
			:data-source="rows"
			:locale="{emptyText: ''}"
		>
			<a-list-item slot="renderItem" slot-scope="item">
				<a-list-item-meta>
					<a-avatar size="small" v-if="item.type == 'create'" slot="avatar" style="background-color: #EDF9E7">
						<svg width="10" height="10" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path class="fill-success" fill-rule="evenodd" clip-rule="evenodd" d="M10 3C10.5523 3 11 3.44772 11 4V9H16C16.5523 9 17 9.44772 17 10C17 10.5523 16.5523 11 16 11H11V16C11 16.5523 10.5523 17 10 17C9.44772 17 9 16.5523 9 16V11H4C3.44772 11 3 10.5523 3 10C3 9.44771 3.44772 9 4 9L9 9V4C9 3.44772 9.44772 3 10 3Z"/>
						</svg>
					</a-avatar>
					<a-avatar size="small" v-else-if="item.type == 'delete'" slot="avatar" style="background-color: #FEE9EA">
						<svg width="10" height="10" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path class="fill-danger" fill-rule="evenodd" clip-rule="evenodd" d="M5 10C5 9.44772 5.44772 9 6 9L14 9C14.5523 9 15 9.44772 15 10C15 10.5523 14.5523 11 14 11L6 11C5.44772 11 5 10.5523 5 10Z"/>
						</svg>
					</a-avatar>
					<a-avatar size="small" v-else slot="avatar" style="background-color: #FEE9EA">
						<a-icon type="info" theme="outlined" />
					</a-avatar>
					<div slot="title">
						{{item.resources}}: {{item.note}}
					</div>
					<div slot="description">
						<a-progress v-if="item.status === 'SUCCESS'" :percent="item.percent" status="success" />
						<a-progress v-else :percent="item.percent" />
						{{item.created_at | moment("DD/MM/YYYY HH:mm")}}
					</div>
				</a-list-item-meta>
			</a-list-item>
		</a-list>
	</div>
</template>

<script>
	import jobService from "@/services/job"
	import { notification } from 'ant-design-vue'

	export default ({
		data() {
			return {
				rows: [],
			}
		},
		created() {
			this.getData()
			setTimeout(() => {
				if (!this._.isEmpty(this.rows)) {
					return;
				}
				this.getData()
			}, 3000);
		},
		methods: {
			getData() {
				jobService.listing()
				.then(res=> {
					if (!this._.isArray(res) || this._.isEmpty(res)) {
						return;
					}
					this.rows = res
					this._.each(this.rows, (row, index)=> {
						setTimeout(() => {
							this.getDetail(row, index)
						}, 5000);
					})
				})
			},
			getDetail(row, index) {
				jobService.detail(row.id)
				.then(res=> {
					if (!this._.isObject(res) || this._.isEmpty(res)) return
					this.rows[index] = res
					this.rows 		 = this._.clone(this.rows)
					if (res.percent < 100)
						setTimeout(() => {
							this.getDetail(row, index)
						}, 5000);
					else {
						this.rows.splice(index, 1)
						switch (res.resources) {
							case 'Server':
								notification.success({
									message: this.$t('notify.success.createServer')
								})
								this.$emit('serverReload')
							break;
							case 'Delete Server':
								this.$emit('serverReload')
							break;
							case 'Resize Server':
								this.$emit('resizeServerReload')
							break;
							case 'IP':
								this.$emit('ipReload')
							break;
							case 'Volume':
								notification.success({
									message: this.$t('notify.success.createVolume')
								})
								this.$emit('volumeReload')
							break;
							case 'Resize Volume':
								notification.success({
									message: this.$t('notify.success.extendVolume')
								})
								this.$emit('resizeVolumeReload')
							break;
							case 'Snapshot':
								notification.success({
									message: this.$t('notify.success.createSnapshot')
								})
								this.$emit('snapshotReload')
							break;
						}
					}
				})
			}
		}
	})

</script>